/* global app gtag */
window.app = window.app || {};

app.ga = {
  trackShareClick: function($el) {
    if (window.gtag) {
      gtag('event', 'share', {
        'event_category': 'click',
        'event_label': $el.data('media')
      });
    }
  },

  getProductData: function($el) {
    var data = $el.closest('[data-ga]').data('ga');
    var $position = $el.closest('[data-ga-position]');
    var $list =  $el.closest('[data-ga-list]');

    if (data.id) {
      data.item_id = data.id;
    }
    if (data.name) {
      data.item_name = data.name;
    }
    if (data.brand) {
      data.item_brand = data.brand;
    }
    if (data.category) {
      data.item_category = data.category;
    }
    if ($position.length) {
      data.list_position = $position.data('ga-position');
      data.index = data.list_position;
    }
    if ($list.length) {
      data.list_name = $list.data('ga-list');
      data.item_list_name = data.list_name;
    }
    return data;
  }
};

$(function(){

  var items = [];
  $('[ga-track="productImpression"]').each(function(){
    items.push(app.ga.getProductData($(this)));
  });

  if (window.gtag && items.length) {
    gtag('event', 'view_item_list', {
      'items': items
    });
  }

  $(document).on('click', '[ga-track="productClick"]', function(){
    var data = app.ga.getProductData($(this));

    if (app.utils.isSessionStorageSupported()) {
      var itemLists = JSON.parse(sessionStorage.getItem('app:itemLists') || '{}');

      itemLists[data.id] = {
        'list_name': data.list_name,
        'item_list_name': data.list_name,
        'list_position': data.list_position,
      };
      sessionStorage.setItem('app:itemLists', JSON.stringify(itemLists));
    }

    if (window.gtag) {
      gtag('event', 'select_item', {
        'items': [data]
      });
    }
  });

});
